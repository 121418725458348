define('wqxr-web-client/mirage-models/pledge', ['exports', 'nypr-account-settings/mirage-models/pledge'], function (exports, _pledge) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _pledge.default;
    }
  });
});