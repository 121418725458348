define('wqxr-web-client/helpers/media', ['exports', 'ember-responsive/helpers/media'], function (exports, _media) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _media.default;
    }
  });
  Object.defineProperty(exports, 'media', {
    enumerable: true,
    get: function () {
      return _media.media;
    }
  });
});