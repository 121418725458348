define('wqxr-web-client/initializers/ember-hotjar', ['exports', 'wqxr-web-client/hotjar/main'], function (exports, _main) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() {

    var application = arguments[0];
    if (arguments.length === 2) {
      //for ember 1.x
      var container = arguments[0];
      application = arguments[1];
      container.options('hotjar:main');
    }

    var h = _main.default.create();
    application.register('hotjar:main', h, { instantiate: false });
    application.inject('controller', '_hj', 'hotjar:main');
    application.inject('route', '_hj', 'hotjar:main');
  }
  exports.default = {
    name: 'ember-hotjar',
    initialize: initialize
  };
});