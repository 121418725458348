define('wqxr-web-client/components/nypr-accounts/social-card', ['exports', 'nypr-account-settings/components/nypr-accounts/social-card'], function (exports, _socialCard) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _socialCard.default;
    }
  });
});