define('wqxr-web-client/transitions/slide-toggle', ['exports', 'liquid-fire'], function (exports, _liquidFire) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = slideToggle;
  function slideToggle() {
    var animationSettings = {
      easing: [0.2, 0.2, 0.2, 0.2],
      duration: 200
    };
    var oldStick = this.oldElement.find('.switch');
    var oldText = this.oldElement.find('.label');
    var animateSetting = {};

    if (this.newValue) {
      oldStick.css('order', '2');
      oldText.css('order', '1');
      this.newElement.find('.autoplay-toggle').css('backgroundColor', '#BBB');
      this.newElement.find('.label').css('order', '2');
      this.newElement.find('.switch').css('order', '1');
      animateSetting.toggle = { translateX: ['-35px', 0] };
      animateSetting.text = { translateX: [0, '35px'] };
      animateSetting.body = { backgroundColor: ['#BBB', '#00aeff'] };
    } else {
      this.oldElement.find('.autoplay-toggle').css({
        backgroundColor: '#BBB'
      });
      this.newElement.find('.autoplay-toggle').css('backgroundColor', '#00aeff');
      oldText.css('order', '2');
      oldStick.css('order', '1');
      this.newElement.find('.label').css({ order: '1' });
      this.newElement.find('.switch').css({ order: '2' });
      animateSetting.body = { backgroundColor: ['#00aeff', '#BBB'] };
      animateSetting.toggle = { translateX: ['35px', 0] };
      animateSetting.text = { translateX: [0, '-35px'] };
    }

    this.newElement.css('visibility', '');

    return _liquidFire.Promise.all([(0, _liquidFire.animate)(oldStick, animateSetting.toggle, animationSettings), (0, _liquidFire.animate)(oldText, animateSetting.text, animationSettings), (0, _liquidFire.animate)(this.oldElement.find('.autoplay-toggle'), animateSetting.body, animationSettings)]);
  }
});