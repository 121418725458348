define('wqxr-web-client/components/ivy-tabs-tab', ['exports', 'ivy-tabs/components/ivy-tabs-tab'], function (exports, _ivyTabsTab) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _ivyTabsTab.default;
    }
  });
});