define('wqxr-web-client/routes/validate', ['exports', 'wqxr-web-client/mixins/deauthenticated-route-mixin'], function (exports, _deauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_deauthenticatedRouteMixin.default, {
    fastboot: Ember.inject.service(),
    isFastBoot: Ember.computed.reads('fastboot.isFastBoot'),
    titleToken: 'Validate',

    renderTemplate: function renderTemplate() {
      // don't render this page in fastboot. It causes a double confirmation when
      // page loads.
      if (this.get('isFastBoot')) {
        return;
      }
      return this._super.apply(this, arguments);
    },


    actions: {
      didTransition: function didTransition() {
        this.send('disableChrome');
      },
      willTransition: function willTransition() {
        this.send('enableChrome');
      }
    }
  });
});