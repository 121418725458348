define('wqxr-web-client/components/stream-banner/station-name/component', ['exports', 'nypr-audio-services/components/stream-banner/station-name'], function (exports, _stationName) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _stationName.default.extend({
        activeStream: null,
        hifi: Ember.inject.service(),
        isPlaying: Ember.computed('hifi.isPlaying', 'activeStream', function () {
            return this.get('hifi.isPlaying') && this.get('hifi.currentSound.metadata.contentId') === this.get('activeStream.slug');
        })
    });
});