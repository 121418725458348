define('wqxr-web-client/components/nypr-accounts/social-connect-button', ['exports', 'nypr-account-settings/components/nypr-accounts/social-connect-button'], function (exports, _socialConnectButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _socialConnectButton.default;
    }
  });
});