define('wqxr-web-client/controllers/djangorendered', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['next', 'q'],
    next: null,
    q: null
  });
});