define('wqxr-web-client/components/site-chrome/component', ['exports', 'wqxr-web-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    donateURL: _environment.default.wnycDonateURL,
    classNameBindings: ['showPlayer:sitechrome--player-open'],
    preferredStream: Ember.computed.or('session.data.user-prefs-active-stream', 'defaultStream'),
    init: function init() {
      this._super.apply(this, arguments);
      this.setProperties({
        defaultStream: { slug: 'wqxr', name: 'WQXR 105.9 FM' },
        socialIcons: [{ url: 'http://www.twitter.com/WQXR', icon: 'twitter' }, { url: 'http://www.facebook.com/WQXRClassical', icon: 'facebook' }, { url: 'https://www.instagram.com/wqxr_classical/', icon: 'instagram' }, { url: 'http://www.youtube.com/user/WQXRClassical', icon: 'youtube' }]
      });
    },

    actions: {
      routeSearch: function routeSearch(val) {
        this.get('router').transitionTo('djangorendered', 'search/', { queryParams: { q: val } });
      }
    }
  });
});