define('wqxr-web-client/resolver', ['exports', 'ember-resolver'], function (exports, _emberResolver) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var RE = /^(?:route|template|controller):((show|series|article|tag|blog)-detail)(?:$|[/-].*)/;

  exports.default = _emberResolver.default.extend({
    parseName: function parseName(fullName) {
      if (RE.test(fullName)) {
        var match = RE.exec(fullName);
        return this._super(fullName.replace(match[1], 'channel'));
      }
      return this._super(fullName);
    }
  });
});