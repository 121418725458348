define('wqxr-web-client/transitions/navigation-bar', ['exports', 'liquid-fire'], function (exports, _liquidFire) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    var _this = this;

    var explode = this.lookup('explode');
    var oldNavLeft = void 0;
    if (this.oldElement) {
      oldNavLeft = this.oldElement.find('ul')[0].scrollLeft;
    }
    this.newElement.find('ul')[0].scrollLeft = oldNavLeft || 0;

    return explode.call(this, {
      pick: '.nav-bar',
      use: ['fly-to', { duration: 250 }]
    }).then(function () {
      if (_this.oldElement) {
        _this.oldElement.hide();
      }
      return (0, _liquidFire.animate)(_this.newElement.find('.is-active'), 'scroll', {
        axis: 'x',
        container: _this.newView.$('.nav-links__list')
      });
    });
  };
});