define('wqxr-web-client/controllers/reset', ['exports', 'wqxr-web-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['confirmation', 'email'],
    confirmation: null,
    email: null,
    config: _environment.default
  });
});