define('wqxr-web-client/playlist-daily/date/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    poll: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    isFastBoot: Ember.computed.reads('fastboot.isFastBoot'),

    titleToken: function titleToken(model) {
      return Ember.get(model, 'title');
    },

    queryParams: {
      scheduleStation: {
        refreshModel: true
      }
    },
    model: function model(_ref) {
      var year = _ref.year,
          month = _ref.month,
          day = _ref.day,
          scheduleStation = _ref.scheduleStation;

      // django pages don't work w/ FastBoot, so only execute this in browser
      if (this.get('isFastBoot')) {
        return { 'title': 'Play History' };
      }
      // year, month, day, and scheduleStation will all be available vars
      return this.store.findRecord('django-page', 'playlist-daily/' + year + '/' + month + '/' + day + '/?scheduleStation=' + scheduleStation, { reload: true });
    },


    actions: {
      didTransition: function didTransition() {
        var _this = this;

        this.get('poll').addPoll({
          interval: 60 * 1000 * 5,
          callback: function callback() {
            return _this.refresh();
          },
          label: 'refresh'
        });
      },
      willTransition: function willTransition() {
        this.get('poll').clearPollByLabel('refresh');
      }
    }
  });
});