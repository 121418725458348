define('wqxr-web-client/components/error-page/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ASSET_MAP = {
    '404': '/assets/img/error/violinist.jpg'
  };

  var TEXT_MAP = {
    '404': {
      headline: "Something's missing",
      sub: "(404) We can't find the page you're looking for. Please check the url and try again."
    }
  };

  exports.default = Ember.Component.extend({
    classNames: ['error-page'],
    attributeBindings: ['style'],

    style: Ember.computed('errorCode', function () {
      var imagePath = ASSET_MAP[this.get('errorCode')];
      return Ember.String.htmlSafe('background-image: url(' + imagePath + ');');
    }),
    headline: Ember.computed('errorCode', function () {
      var text = TEXT_MAP[this.get('errorCode')];
      if (text) {
        return text.headline;
      }
    }),
    sub: Ember.computed('errorType', function () {
      var text = TEXT_MAP[this.get('errorCode')];
      if (text) {
        return text.sub;
      }
    })
  });
});