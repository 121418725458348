define('wqxr-web-client/routes/topics', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: 'Browse Stories by Category or Topic',
    templateName: 'djangorendered',
    model: function model() {
      return this.store.find('django-page', 'topics/');
    }
  });
});