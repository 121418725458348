define('wqxr-web-client/helpers/abbreviate-time', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.abbreviateTime = abbreviateTime;
  function abbreviateTime(params /*, hash*/) {

    return params[0].replace(/seconds?/, 'sec').replace(/minutes?/, 'min').replace(/hours?/, 'hr');
  }

  exports.default = Ember.Helper.helper(abbreviateTime);
});