define('wqxr-web-client/playlist/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    redirect: function redirect(model, _ref) {
      var slug = _ref.slug;

      this.transitionTo('playlist-daily', {
        queryParams: { scheduleStation: slug }
      });
    }
  });
});