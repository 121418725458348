define('wqxr-web-client/components/ivy-tabs', ['exports', 'ivy-tabs/components/ivy-tabs'], function (exports, _ivyTabs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _ivyTabs.default;
    }
  });
});