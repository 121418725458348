define('wqxr-web-client/components/social-iconlist', ['exports', 'nypr-publisher-lib/components/social-iconlist'], function (exports, _socialIconlist) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _socialIconlist.default;
    }
  });
});