define('wqxr-web-client/routes/index', ['exports', 'wqxr-web-client/mixins/play-param', 'ember-data'], function (exports, _playParam, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var STREAM_BG = '/assets/img/backgrounds/transparent.png';

  exports.default = Ember.Route.extend(_playParam.default, {
    googleAds: Ember.inject.service(),
    classNames: ['home'],
    dj: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    metadata: Ember.inject.service(),

    model: function model() {
      Ember.get(this, 'googleAds').doTargeting();
      var hash = {
        wqxrHome: this.store.findRecord('bucket', 'wqxr-home').then(function (b) {
          return {
            featuredItems: b.get('bucketItems').slice(0, 9),
            otherItems: b.get('bucketItems').slice(9)
          };
        })
      };
      // django pages don't work w/ FastBoot, and these chunks are rendered with
      // the django page component, so don't load these until the browser environment
      if (!this.get('fastboot.isFastBoot')) {
        hash.wartopChunk = this.store.findRecord('chunk', 'wqxr-wartop-home').catch(function () {
          return '';
        });
        hash.liveChunk = this.store.findRecord('chunk', 'wqxr-live-home').catch(function () {
          return '';
        });
        hash.membershipChunk = this.store.findRecord('chunk', 'wqxr-membership-home').catch(function () {
          return '';
        });
      }
      return Ember.RSVP.hash(hash);
    },
    afterModel: function afterModel() {
      this.get('metadata').setHeadData({
        path: ''
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      var streams = _emberData.default.PromiseArray.create({
        promise: this.store.findAll('stream', { reload: true }).then(function (s) {
          return s.filterBy('liveWQXR').sortBy('sitePriority').concat(s.filterBy('liveWNYC').sortBy('sitePriority')).uniq();
        })
      });
      controller.set('streams', streams);
      controller.set('background', STREAM_BG);
    }
  });
});