define('wqxr-web-client/adapters/stream', ['exports', 'nypr-publisher-lib/adapters/stream', 'ember-simple-auth/mixins/data-adapter-mixin', 'fetch'], function (exports, _stream, _dataAdapterMixin, _fetch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var json = function json(r) {
    return r.json();
  };

  exports.default = _stream.default.extend(_dataAdapterMixin.default, {
    authorize: function authorize(xhr) {
      var headers = this.get('session').authorize({});
      for (var h in headers) {
        xhr.setRequestHeader(h, headers[h]);
      }
    },
    findAll: function findAll() {
      var base = this.host + '/' + this.namespace;
      return Ember.RSVP.hash({
        streams: (0, _fetch.default)(base + '/list/streams/').then(json),
        whatsOn: (0, _fetch.default)(base + '/whats_on/?previous=1').then(json)
      });
    },
    findRecord: function findRecord(store, type, id /*, snapshot*/) {
      var base = this.host + '/' + this.namespace;
      return Ember.RSVP.hash({
        stream: (0, _fetch.default)(base + '/list/streams/' + id + '/').then(json),
        whatsOn: (0, _fetch.default)(base + '/whats_on/' + id + '/1').then(json)
      });
    }
  });
});