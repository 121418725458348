define('wqxr-web-client/helpers/humanize-duration', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.humanizeDuration = humanizeDuration;
  function humanizeDuration(params /*, hash*/) {
    var h = _moment.default.duration(params[0], 'seconds').get('hours');
    var m = _moment.default.duration(params[0], 'seconds').get('minutes');
    var s = _moment.default.duration(params[0], 'seconds').get('seconds');

    if (h > 0) {
      return (withUnits(h, 'hour') + ' ' + withUnits(m, 'minute')).trim();
    } else if (m > 0) {
      if (s > 29) {
        m = m + 1; // round up the minutes
      }
      return ('' + withUnits(m, 'minute')).trim();
    } else {
      return ('' + withUnits(s, 'second')).trim();
    }
  }

  function withUnits(value, singularUnit) {
    var unit = '' + (value === 1 ? singularUnit : singularUnit + 's');
    if (value > 0) {
      return value + ' ' + unit;
    } else {
      return "";
    }
  }

  exports.default = Ember.Helper.helper(humanizeDuration);
});