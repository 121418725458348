define('wqxr-web-client/locations/trailing-history', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HistoryLocation.extend({
    formatURL: function formatURL() {
      var url = this._super.apply(this, arguments);

      return url.replace(/\/?(\?|#|$)/, '/$1');
    }
  });
});