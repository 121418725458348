define('wqxr-web-client/components/queue-history', ['exports', 'nypr-audio-services/components/queue-history'], function (exports, _queueHistory) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _queueHistory.default;
    }
  });
});