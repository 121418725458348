define('wqxr-web-client/utils/toggle-box-positioner', ['exports', 'ember-basic-dropdown/utils/calculate-position'], function (exports, _calculatePosition) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = calculatePosition;
  function calculatePosition(trigger, content, _destination, ref) {
    // Get initial settings from the default positioner
    var obj = _calculatePosition.default.apply(undefined, arguments);

    var _trigger$getBoundingC = trigger.getBoundingClientRect(),
        triggerLeft = _trigger$getBoundingC.left,
        triggerWidth = _trigger$getBoundingC.width;

    var _content$getBoundingC = content.getBoundingClientRect(),
        contentWidth = _content$getBoundingC.width;

    var viewportWidth = document.body.clientWidth || window.innerWidth;

    var verticalPosition = obj.verticalPosition;

    var bottomOffset = verticalPosition == 'above' ? -10 : 10;

    // Set these attributes on the dropdown object so we can put the tab in the right spot
    content.setAttribute('data-v-pos', verticalPosition);

    obj['style']['top'] = obj['style']['top'] + bottomOffset;

    var idealCenter = triggerLeft + triggerWidth / 2;
    var idealRight = viewportWidth - (idealCenter + contentWidth / 2);
    var viewportMargin = 12; // space between popup and edge of screen

    var right = idealRight;
    if (right < viewportMargin) {
      // push to the left
      right = viewportMargin;
    }

    var leftOverflow = right + contentWidth + viewportMargin;
    if (leftOverflow > viewportWidth) {
      obj['style']['left'] = viewportMargin;
      obj['horizontalPosition'] = "left";
      delete obj['style']['right'];
    } else {
      obj['style']['right'] = right;
      obj['horizontalPosition'] = "right";
      delete obj['style']['left'];
    }

    // Apply ember-basic-dropdown's repositioning
    ref.dropdown.applyReposition(trigger, content, obj);

    return obj;
  }
});