define('wqxr-web-client/story/route', ['exports', 'wqxr-web-client/mixins/play-param', 'wqxr-web-client/config/environment'], function (exports, _playParam, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_playParam.default, {
    session: Ember.inject.service(),
    googleAds: Ember.inject.service(),
    dataPipeline: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    isFastBoot: Ember.computed.reads('fastboot.isFastBoot'),
    dataLayer: Ember.inject.service('nypr-metrics/data-layer'),
    metadata: Ember.inject.service(),

    titleToken: function titleToken(_ref) {
      var story = _ref.story;

      return [Ember.get(story, 'title'), Ember.get(story, 'showTitle') || Ember.get(story, 'channelTitle') || 'NPR Article'];
    },
    model: function model(_ref2, _ref3) {
      var _this = this;

      var slug = _ref2.slug;
      var queryParams = _ref3.queryParams;


      return this.store.findRecord('story', slug, { adapterOptions: { queryParams: queryParams } }).then(function (story) {
        var comments = _this.store.query('comment', { itemTypeId: story.get('itemTypeId'), itemId: story.get('cmsPK') });
        var relatedStories = _this.store.query('story', { related: { itemId: story.get('cmsPK'), limit: 5 } });
        var imageGrid = _this.store.findRecord('bucket', 'articles-' + slug + '-imagegrid').then(function (i) {
          if (i) {
            return i.get('bucketItems');
          } else {
            return {};
          }
        }).catch(function () {
          return '';
        });

        return Ember.RSVP.hash({
          story: story,
          getComments: function getComments() {
            return comments;
          },
          getRelatedStories: function getRelatedStories() {
            return relatedStories;
          },
          imageGrid: imageGrid,
          adminURL: _environment.default.adminRoot + '/admin'
        });
      });
    },
    afterModel: function afterModel(_ref4, transition) {
      var _this2 = this;

      var story = _ref4.story;

      Ember.get(this, 'googleAds').doTargeting(story.forDfp());

      if (Ember.get(story, 'headerDonateChunk')) {
        transition.send('updateDonateChunk', Ember.get(story, 'headerDonateChunk'));
      }
      // dataLayer access dom here, which is not available in FastBoot, so don't do this yet.
      if (!Ember.get(this, 'isFastBoot')) {
        Ember.get(this, 'dataLayer').setForType('story', story);
      }

      this.get('metadata').setHeadData({
        type: 'article',
        path: '/story/' + Ember.get(story, 'slug'),
        twitterCard: 'summary_large_image',
        description: Ember.get(story, 'tease'),
        image: Ember.get(story, 'imageMain')
      });

      Ember.run.schedule('afterRender', function () {
        // data pipeline
        Ember.get(_this2, 'dataPipeline').reportItemView({
          cms_id: Ember.get(story, 'cmsPK'),
          item_type: Ember.get(story, 'itemType')
        });
      });
    },
    setupController: function setupController(controller) {
      // can't access window in FastBoot, only do this in broswer
      if (!Ember.get(this, 'isFastBoot')) {
        controller.set('isMobile', window.Modernizr.touchevents);
      }
      controller.set('session', Ember.get(this, 'session'));
      controller.set('user', Ember.get(this, 'currentUser.user'));

      return this._super.apply(this, arguments);
    },
    renderTemplate: function renderTemplate(controller, model) {
      if (Ember.get(model, 'story.template') === 'story_full-bleed') {
        this.send('disableChrome');
        var image = Ember.get(model, 'story.imageMain');
        // This logic is yields three cases corresponding to the three possible full-bleed template layouts:
        // Big lead image, small lead image, and no lead image. If there is an image, and the width is big enough,
        // don't show additional share links or the small lead image. If there is an image but it's small, show
        // both the additional share links and the small lead. If there is no lead image, show only the additional share links.
        if (image) {
          controller.set('showSmallLead', image.w < 1440);
          controller.set('showShareLinks', image.w < 1440);
        } else {
          controller.set('showSmallLead', false);
          controller.set('showShareLinks', true);
        }
        this.render('full-bleed');
      } else {
        this._super.apply(this, arguments);
      }
    },


    actions: {
      willTransition: function willTransition() {
        this.send('enableChrome');
        Ember.get(this, 'dataLayer').clearForType('story');
      }
    }
  });
});