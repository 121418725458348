define('wqxr-web-client/mixins/image-loader', ['exports', 'nypr-ui/mixins/image-loader'], function (exports, _imageLoader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _imageLoader.default;
    }
  });
  Object.defineProperty(exports, 'imageLoader', {
    enumerable: true,
    get: function () {
      return _imageLoader.imageLoader;
    }
  });
});