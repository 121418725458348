define('wqxr-web-client/events/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    fastboot: Ember.inject.service(),
    isFastBoot: Ember.computed.reads('fastboot.isFastBoot'),

    titleToken: function titleToken(model) {
      return Ember.get(model, 'title');
    },
    model: function model() {
      // django pages don't work w/ FastBoot, so only execute this in browser
      if (this.get('isFastBoot')) {
        return { 'title': 'Events' };
      }
      return this.store.findRecord('django-page', 'events/');
    }
  });
});