define('wqxr-web-client/routes/djangorendered', ['exports', 'ember-data', 'wqxr-web-client/config/environment', 'nypr-django-for-ember/utils/compat-hooks', 'wqxr-web-client/mixins/play-param'], function (exports, _emberData, _environment, _compatHooks, _playParam) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_playParam.default, {
    queryParams: {
      q: {
        refreshModel: true
      }
    },
    googleAds: Ember.inject.service(),
    metadata: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    isFastBoot: Ember.computed.reads('fastboot.isFastBoot'),

    titleToken: function titleToken(model) {
      var title = Ember.get(model, 'title');
      if (title) {
        return title.split(' | ')[0];
      } else {
        return 'Not Found';
      }
    },
    model: function model(_ref, _ref2) {
      var upstream_url = _ref.upstream_url;
      var queryParams = _ref2.queryParams;

      // This adds trailing slashes, because the server's redirect
      // doesn't otherwise work correctly due to the proxying I'm using
      // in development (which is neeeded due to CORs).
      upstream_url = upstream_url.replace(/\/*$/, '/');

      var qp = Object.keys(queryParams).filter(function (q) {
        return queryParams[q] && _environment.default.QP_WHITELIST.includes(q);
      }).map(function (p) {
        return p + '=' + queryParams[p].replace(/\s/g, '%20');
      });
      if (qp.length) {
        upstream_url += '?' + qp.join('&');
      }

      return this.store.find('django-page', upstream_url).catch(function (e) {
        if (e instanceof _emberData.default.NotFoundError) {
          throw e;
        }
        (0, _compatHooks.retryFromServer)(e, upstream_url);
      });
    },
    afterModel: function afterModel(transition) {
      Ember.get(this, 'googleAds').doTargeting();
      this.get('metadata').setHeadData({
        path: Ember.get(transition, 'intent.url')
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      // can't reference the doc in FastBoot, only execute this in browser
      if (this.get('fastboot.isFastBoot')) {
        return;
      }
      var doc = model.get('document');
      var classNamesForRoute = [];
      if (!doc.querySelector('.graphic-responsive')) {
        classNamesForRoute.push('l-constrained');
      }
      if (model.get('id') === 'search/') {
        classNamesForRoute.push('search');
      }
      controller.set('classNamesForRoute', classNamesForRoute);
    },


    actions: {
      willTransition: function willTransition() {
        this._super.apply(this, arguments);
        (0, _compatHooks.beforeTeardown)();
        return true;
      }
    }
  });
});