define('wqxr-web-client/components/nypr-account-forms/reset', ['exports', 'nypr-account-settings/components/nypr-account-forms/reset'], function (exports, _reset) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _reset.default;
    }
  });
});