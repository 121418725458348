define('wqxr-web-client/components/nypr-player/volume-control', ['exports', 'nypr-player/components/nypr-player/volume-control'], function (exports, _volumeControl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _volumeControl.default;
    }
  });
});