define('wqxr-web-client/playlist-daily/index/route', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    redirect: function redirect(model, _ref) {
      var targetName = _ref.targetName,
          queryParams = _ref.queryParams;

      if (targetName !== 'playlist-daily.date') {
        var year = (0, _moment.default)().format('YYYY');
        var month = (0, _moment.default)().format('MMM').toLowerCase();
        var day = (0, _moment.default)().format('DD');
        this.replaceWith('playlist-daily.date', year, month, day, {
          queryParams: {
            scheduleStation: queryParams.scheduleStation || 'wqxr'
          }
        });
      }
    }
  });
});