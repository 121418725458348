define('wqxr-web-client/components/player-history', ['exports', 'nypr-audio-services/components/player-history'], function (exports, _playerHistory) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _playerHistory.default;
    }
  });
});