define('wqxr-web-client/routes/application', ['exports', 'ember-data', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _emberData, _applicationRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DETAIL_ROUTES = new RegExp(/story|(show|article|series|tag|blog)-detail\./);

  exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
    dataLayer: Ember.inject.service('nypr-metrics/data-layer'),
    dataPipeline: Ember.inject.service(),
    asyncWriter: Ember.inject.service(),
    legacyLoader: Ember.inject.service(),
    leaderboard: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    session: Ember.inject.service(),
    poll: Ember.inject.service(),
    store: Ember.inject.service(),
    dj: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    isFastBoot: Ember.computed.reads('fastboot.isFastBoot'),

    title: function title() {
      var _this = this;

      var tokens = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

      var siteName = 'WQXR';
      var tagline = "New York's Classical Music Radio Station";

      // combine the first two items if the second item stats with `:`
      if (tokens[1] && tokens[1].startsWith(':')) {
        tokens.splice(0, 2, tokens[0] + ' ' + tokens[1]);
      }

      tokens.push(siteName);
      if (tokens.length < 3) {
        tokens.push(tagline);
      }
      var title = tokens.join(' | ');
      Ember.get(this, 'dataLayer').setPageTitle(title);

      if (!this.controller._wasModal) {
        var route = this.router.currentRouteName;

        Ember.run.schedule('afterRender', function () {
          Ember.get(_this, 'dataLayer').sendPageView();
          if (!DETAIL_ROUTES.test(route) && !route.match(/loading/)) {
            _this.get('dataPipeline').reportItemView();
          }
        });
      } else {
        // reset
        this.controller._wasModal = false;
      }

      return title;
    },
    beforeModel: function beforeModel() {
      var _this2 = this;

      Ember.get(this, 'currentUser').load();

      // can't reference window in FastBoot, only execute in browser.
      // make sure Fastboot instance doesn't try to refresh auth credentials.
      if (Ember.get(this, 'isFastBoot')) {
        this.set('session.noRefresh', true);
        return;
      }

      Ember.get(this, 'session').syncBrowserId().then(function (id) {
        return Ember.get(_this2, 'dj').addBrowserId(id);
      });
      Ember.get(this, 'session').staffAuth();

      Ember.get(this, 'dataLayer').setLoggedIn(false);

      Ember.get(this, 'asyncWriter').install();
      Ember.get(this, 'leaderboard').install();

      window.WNYC_LEGACY_LOADER = Ember.get(this, 'legacyLoader');

      var setShowMetadata = function setShowMetadata(stream, show) {
        var hostImageTemplateUrl = Ember.get(show, 'about.people.firstObject.image.template');
        stream.set('hostImageTemplateUrl', hostImageTemplateUrl || undefined);

        var hostImageCropSetting = Ember.get(show, 'about.people.firstObject.image.crop');
        stream.set('hostImageCropSetting', hostImageCropSetting || undefined);
      };

      var clearShowMetadata = function clearShowMetadata(stream) {
        stream.set('hostImageTemplateUrl', undefined);
        stream.set('hostImageCropSetting', undefined);
      };

      var self = this;
      var pollFunction = function pollFunction() {
        Ember.get(self, 'store').findAll('stream', { reload: true }).then(function (streams) {
          streams.forEach(function (stream) {
            var showSlug = stream.get('currentShow') ? stream.get('currentShow').group_slug : null;
            if (showSlug && showSlug !== 'airing') {
              var show = Ember.get(self, 'store').peekRecord('show', showSlug);
              if (show) {
                setShowMetadata(stream, show);
              } else {
                Ember.get(self, 'store').findRecord('show', showSlug).then(function (show) {
                  setShowMetadata(stream, show);
                });
              }
            } else {
              clearShowMetadata(stream);
            }
          });
        });
      };

      pollFunction();
      Ember.get(this, 'poll').addPoll({ interval: 10 * 1000, callback: pollFunction });
    },
    model: function model() {
      // django pages don't work w/ FastBoot, and these chunks are rendered with
      // the django page component, so don't load these until the browser environment
      if (Ember.get(this, 'isFastBoot')) {
        return;
      }
      return Ember.RSVP.hash({
        splashPage: this.store.findRecord('chunk', 'wqxr-global').catch(function () {
          return '';
        })
      });
    },


    actions: {
      error: function error(_error /*, transition*/) {
        if (_error instanceof _emberData.default.NotFoundError) {
          if (Ember.get(this, 'isFastBoot')) {
            this.set('fastboot.response.statusCode', 404);
          }
          this.transitionTo('404', _error.url);
        } else {
          /* eslint-disable */
          console.error(_error);
          /* eslint-enable */
        }
      },
      didTransition: function didTransition() {
        // can't reference window in FastBoot, only execute in browser
        if (Ember.get(this, 'isFastBoot')) {
          return;
        }
        this.set('dataPipeline.currentReferrer', window.location.toString());
      },
      willTransition: function willTransition() {
        //close queue/history modal when we open a new page
        this.controllerFor('application').send('closeModal');
        this.send('updateDonateChunk', null);
      },
      updateDonateChunk: function updateDonateChunk(donateChunk) {
        this.controllerFor('application').set('headerDonateChunk', donateChunk);
      },
      setMiniChrome: function setMiniChrome(val) {
        this.controllerFor('application').set('miniChrome', val);
      },
      disableChrome: function disableChrome() {
        this.controllerFor('application').set('chromeDisabled', true);
      },
      enableChrome: function enableChrome() {
        this.controllerFor('application').set('chromeDisabled', false);
      }
    },

    sessionAuthenticated: function sessionAuthenticated() {
      this._super.apply(this, arguments);
      Ember.get(this, 'dataLayer').setLoggedIn(true);
      Ember.get(this, 'currentUser').load();
    },
    sessionInvalidated: function sessionInvalidated() {
      Ember.get(this, 'dataLayer').setLoggedIn(false);
      if (this.get('session.noRefresh') === true) {
        this.set('session.noRefresh', false);
      } else {
        this._super.apply(this, arguments);
      }
    }
  });
});