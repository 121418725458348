define('wqxr-web-client/channel/index/route', ['exports', 'wqxr-web-client/mixins/listing-route'], function (exports, _listingRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_listingRoute.default, {
    model: function model() {
      var _this = this;

      var _Ember$getProperties = Ember.getProperties(this, 'channelType', 'channelPathName'),
          channelType = _Ember$getProperties.channelType,
          channelPathName = _Ember$getProperties.channelPathName;

      var _paramsFor = this.paramsFor(channelType),
          slug = _paramsFor.slug;

      var navSlug = this._getNavSlug(channelType);

      var id = channelPathName + '/' + slug + '/' + (navSlug || 'recent_stories') + '/' + 1;

      Ember.set(this, 'pageNumbers.totalPages', 0);

      return this.store.findRecord('api-response', id).then(function (m) {
        // wait until models are loaded to keep UI consistent
        Ember.set(_this, 'pageNumbers.page', 1);
        Ember.set(_this, 'pageNumbers.totalPages', Number(Ember.get(m, 'totalPages')));

        return m;
      });
    }
  });
});