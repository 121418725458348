define('wqxr-web-client/story/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['tab'],
    tab: null,
    fastboot: Ember.inject.service(),
    isFastBoot: Ember.computed.reads('fastboot.isFastBoot'),

    setTab: function setTab() {
      // can't reference location in fastboot, only do this in browser.
      if (Ember.get(this, 'isFastBoot')) {
        return;
      }
      if (location.hash.substr(1) === "transcript") {
        this.set("tab", 'transcript');
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
      Ember.run.scheduleOnce("afterRender", this, this.setTab);
    }
  });
});