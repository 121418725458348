define('wqxr-web-client/instance-initializers/google-experiments', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() /* appInstance */{
    if (window.cxApi) {
      // initializing google experiments api here
      // so an experiment variation is always chosen.
      window.cxApi.chooseVariation();
    }
  }

  exports.default = {
    name: 'google-experiments',
    initialize: initialize
  };
});