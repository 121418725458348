define('wqxr-web-client/validations/nypr-accounts/password-change', ['exports', 'nypr-account-settings/validations/nypr-accounts/password-change'], function (exports, _passwordChange) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _passwordChange.default;
    }
  });
});