define('wqxr-web-client/components/nypr-accounts/membership-card/current-status-detail', ['exports', 'nypr-account-settings/components/nypr-accounts/membership-card/current-status-detail'], function (exports, _currentStatusDetail) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _currentStatusDetail.default;
    }
  });
});