define('wqxr-web-client/serializers/order', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPISerializer.extend({
    attrs: {
      creditCardLast4Digits: { key: 'credit-card-last-4-digits' }
    }
  });
});