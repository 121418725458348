define('wqxr-web-client/mixins/play-param', ['exports', 'ember-query-method/mixins/query-method'], function (exports, _queryMethod) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _queryMethod.default.reopen({
    factory: 'service:dj',
    method: 'play'
  });
});