define('wqxr-web-client/services/whats-on', ['exports', 'nypr-publisher-lib/services/whats-on'], function (exports, _whatsOn) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _whatsOn.default;
    }
  });
});