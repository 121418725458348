define('wqxr-web-client/components/user-settings/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      this.setProperties('autoPlayPrefs', [{ name: 'My Default Stream', field: 'default_stream' }]);
    },

    enableAutoplay: Ember.computed.equal('session.data.user-prefs-active-autoplay', 'no_autoplay'),
    activeStream: Ember.computed('session.data.user-prefs-active-stream.slug', function () {
      var streams = this.get('streams');
      var currentStream = this.getWithDefault('session.data.user-prefs-active-stream', { slug: 'wqxr', name: 'WQXR 105.9 FM' });
      return streams.findBy('slug', currentStream.slug);
    }),

    activePref: Ember.computed('session.data.user-prefs-active-autoplay', function () {
      var _this = this;

      var prefs = this.get('autoPlayPrefs');
      var pref = this.getWithDefault('session.data.user-prefs-active-autoplay', 'default_stream');

      if (pref === 'no_autoplay') {
        return prefs.find(function (p) {
          return p.field === _this.get('prevAutoplayPref');
        });
      }

      return prefs.find(function (p) {
        return p.field === pref;
      });
    }),
    classNames: ['settings-body'],
    prevAutoplayPref: 'default_stream',
    actions: {
      toggleAutoplay: function toggleAutoplay(enableAutoplay) {
        var session = this.get('session');
        var value = enableAutoplay ? this.get('prevAutoplayPref') : 'no_autoplay';
        session.set('data.user-prefs-active-autoplay', value);
      },
      selectStream: function selectStream(stream) {
        var session = this.get('session');
        session.set('data.user-prefs-active-stream', stream.getProperties('slug', 'name'));
      },
      selectAutoPlayPref: function selectAutoPlayPref(_ref) {
        var field = _ref.field;

        var session = this.get('session');
        this.set('prevAutoplayPref', field === 'default_stream' ? field : 'queue');
        session.set('data.user-prefs-active-autoplay', field);
      }
    }
  });
});