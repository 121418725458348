define('wqxr-web-client/controllers/set-password', ['exports', 'wqxr-web-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var FLASH_MESSAGES = {
    set: 'Your password has been successfully updated.',
    edit: 'You can now edit your information.'
  };

  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    config: _environment.default,
    queryParams: ['username', 'code', 'email', 'verification_token', 'email_id'],
    username: null,
    code: null,
    email: null,
    verification_token: null,
    email_id: null,

    showFlash: function showFlash(type) {
      this.get('flashMessages').add({
        message: FLASH_MESSAGES[type],
        type: 'success',
        sticky: true
      });
    },

    actions: {
      afterSetPassword: function afterSetPassword() {
        if (Ember.get(this, 'session.isAuthenticated')) {
          this.showFlash('edit');
          return this.transitionToRoute('profile');
        } else {
          return this.showFlash('set');
        }
      }
    }
  });
});