define('wqxr-web-client/components/nypr-player-integration', ['exports', 'nypr-audio-services/components/nypr-player-integration'], function (exports, _nyprPlayerIntegration) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _nyprPlayerIntegration.default;
    }
  });
});