define('wqxr-web-client/torii-providers/facebook-connect', ['exports', 'nypr-auth/torii-providers/facebook-connect'], function (exports, _facebookConnect) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _facebookConnect.default;
    }
  });
});