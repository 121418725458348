define('wqxr-web-client/services/metadata', ['exports', 'wqxr-web-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    headData: Ember.inject.service(),
    setHeadData: function setHeadData() {
      var metadata = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var defaultMetadata = {
        type: 'website',
        twitterCard: 'summary',
        path: '',
        keywords: 'npr, new york, WQXR, WNYC, arts, culture, classical, music, news, public, radio',
        description: 'WQXR - New York Public Radio',
        image: {
          url: _environment.default.mediaRoot + '/i/300/300/c/80/1/wqxr_square_logo.png',
          w: '300',
          h: '300'
        },
        feeds: []
      };

      // filter out keys with undefined values, so they get replaced
      // by defaults when we merge.
      var filteredData = Object.keys(metadata).filter(function (key) {
        return typeof metadata[key] !== 'undefined';
      }).reduce(function (filteredObject, key) {
        filteredObject[key] = metadata[key];
        return filteredObject;
      }, {});
      var mergedData = Object.assign({}, defaultMetadata, filteredData);
      mergedData.description = mergedData.description.replace(/(<([^>]+)>)/g, "");
      Ember.set(this, 'headData.type', mergedData.type);
      Ember.set(this, 'headData.twitterCard', mergedData.twitterCard);
      Ember.set(this, 'headData.url', 'https:' + _environment.default.webRoot + mergedData.path);
      Ember.set(this, 'headData.keywords', mergedData.keywords);
      Ember.set(this, 'headData.description', mergedData.description);
      Ember.set(this, 'headData.image', mergedData.image);
      Ember.set(this, 'headData.feeds', mergedData.feeds);
      Ember.set(this, 'headData.mediaRoot', _environment.default.mediaRoot);
      Ember.set(this, 'headData.publisherAPI', _environment.default.publisherAPI);
    }
  });
});