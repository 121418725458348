define('wqxr-web-client/session-stores/application', ['exports', 'ember-simple-auth/session-stores/cookie'], function (exports, _cookie) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _cookie.default.extend({
    store: Ember.inject.service(),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.on('sessionDataUpdated', function (d) {
        _this._restoreQueue(d);
        _this._restoreListens(d);
      });
    },
    restore: function restore() {
      var _this2 = this;

      var data = this._super.apply(this, arguments);
      return data.then(function (d) {
        return _this2._restoreQueue(d);
      }).then(function (d) {
        return _this2._restoreListens(d);
      });
    },
    _restoreQueue: function _restoreQueue(data) {
      var store = this.get('store');
      var queue = data.queue;

      if (!queue) {
        return data;
      }
      // convert serialized records to format the store expects
      // from [{data: { .. }}, {data: { ... }}]
      // to   {data: [ { ... }, { ... } ]}
      var payload = { data: queue.mapBy('data') };
      store.pushPayload(payload);

      var stories = queue.mapBy('data.id').map(function (id) {
        return store.peekRecord('story', id);
      });
      Ember.set(data, 'queue', stories);
      return data;
    },
    _restoreListens: function _restoreListens(data) {
      var store = this.get('store');
      var listens = data.listens;

      if (!listens) {
        return data;
      }
      // convert listens to stories
      // from [{ id, story: { data: { ... } } }, { id, story: { data: { ... } } }]
      // to: {data: [ { ... }, { ... } ]}
      var payload = { data: listens.mapBy('story.data') };
      store.pushPayload(payload);
      var stories = listens.mapBy('story.data.id').map(function (id) {
        return store.peekRecord('story', id);
      });
      Ember.set(data, 'listens', listens.map(function (l, i) {
        return { id: l.id, story: stories[i] };
      }));
      return data;
    }
  });
});