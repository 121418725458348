define('wqxr-web-client/models/stream', ['exports', 'nypr-publisher-lib/models/stream'], function (exports, _stream) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _stream.default.extend({
    shareMetadata: Ember.computed('currentShow', 'currentPlaylistItem', function () {
      var shareText = '';
      var shareUrl = '';
      var via = Ember.get(this, 'twitterHandle') || 'WQXR';

      var entry = Ember.get(this, 'currentPlaylistItem.catalogEntry');
      if (entry && entry.composer) {
        shareText = 'I\'m listening to ' + entry.composer.name + ' - ' + entry.title;
        shareUrl = 'http://www.wqxr.org/streams/?stream=' + Ember.get(this, 'slug');
      } else {
        shareText = 'I\'m listening to ' + Ember.get(this, 'currentShow.title');
        shareUrl = Ember.get(this, 'currentShow.url');
      }

      return { shareText: shareText, shareUrl: shareUrl, via: via };
    }),
    previousPlaylistItem: Ember.computed('previous', 'currentPlaylistItem', function () {
      var lastTrack = Ember.get(this, "previous.firstObject");
      if (lastTrack && !this._trackIsStale(lastTrack)) {
        return lastTrack;
      }
      return null;
    }),
    _trackIsStale: function _trackIsStale(track) {
      var secondsSinceEpoch = Math.round(Date.now() / 1000);
      return secondsSinceEpoch - track.startTimeTs > 60 * 60;
    }
  });
});