define('wqxr-web-client/events/archive/route', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    redirect: function redirect() {
      var year = (0, _moment.default)().format('YYYY');
      var month = (0, _moment.default)().format('MMM').toLowerCase();
      this.transitionTo('djangorendered', 'events/' + year + '/' + month);
    }
  });
});