define('wqxr-web-client/transitions/sliding-modal', ['exports', 'liquid-fire'], function (exports, _liquidFire) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    var _this = this;

    var el = void 0;
    var translateY = void 0;
    var finalTransform = void 0;
    var animationOptions = {
      duration: 300,
      easing: 'cubic-bezier(0.22, 0.61, 0.36, 1)'
    };
    if (this.newValue) {
      el = this.newElement;
      translateY = ['0%', '100%'];
      finalTransform = 'translateY(0%)';
      // incoming transition, correct z-index issues before slide
      Ember.$('.django-content').css({ position: 'relative' });
    } else {
      el = this.oldElement;
      translateY = ['100%', '0%'];
      finalTransform = 'translateY(100%)';
      // outgoing transition, make django-content visible before slide
      Ember.$('.django-content').css({ visibility: 'visible' });
    }

    el.css({
      position: 'fixed',
      width: '100%',
      height: '100%',
      top: 0
    });

    return (0, _liquidFire.animate)(el, { translateY: translateY }, animationOptions).then(function () {
      el.css('transform', finalTransform);
      if (_this.newValue && _this.newElement) {
        // sliding up, hide django-content for mobile compat
        Ember.$('.django-content').css({ visibility: 'hidden' });
      } else if (_this.newElement) {
        // sliding down, reset transform/stacking order
        Ember.$('.django-content').css({ position: '' });
      }
    });
  };
});