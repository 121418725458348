define('wqxr-web-client/components/user-settings/placeholder/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['ember-power-select-selected-item']
  });
});