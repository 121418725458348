define('wqxr-web-client/routes/set-password', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service(),

    model: function model(params) {
      if (Ember.get(this, 'session.isAuthenticated')) {
        this.transitionTo('/profile');
      } else if (!Ember.get(params, 'username')) {
        this.transitionTo('/');
      }
    },


    actions: {
      didTransition: function didTransition() {
        this.send('disableChrome');
      },
      willTransition: function willTransition() {
        this.send('enableChrome');
      }
    }
  });
});