define('wqxr-web-client/components/nypr-card/header', ['exports', 'nypr-ui/components/nypr-card/header'], function (exports, _header) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _header.default;
    }
  });
});