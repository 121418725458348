define('wqxr-web-client/channel/page/route', ['exports', 'wqxr-web-client/mixins/listing-route'], function (exports, _listingRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Route.extend(_listingRoute.default, {
    model: function model(_ref) {
      var _this = this;

      var page_params = _ref.page_params;

      var _Ember$getProperties = Ember.getProperties(this, 'channelType', 'channelPathName'),
          channelType = _Ember$getProperties.channelType,
          channelPathName = _Ember$getProperties.channelPathName;

      var _paramsFor = this.paramsFor(channelType),
          slug = _paramsFor.slug;

      var _page_params$split = page_params.split('/'),
          _page_params$split2 = _slicedToArray(_page_params$split, 2),
          navSlug = _page_params$split2[0],
          page = _page_params$split2[1];

      if (!page && /^\d+$/.test(navSlug)) {
        // navSlug is a page, so URL is something like shows/bl/5
        // we use recent_stories as the lookup for those stories
        page = navSlug;
        navSlug = 'recent_stories';
      }

      var id = channelPathName + '/' + slug + '/' + navSlug + '/' + (page || 1);
      Ember.set(this, 'pageNumbers.totalPages', 0);

      return this.store.findRecord('api-response', id).then(function (m) {
        // wait until models are loaded to keep UI consistent
        Ember.set(_this, 'pageNumbers.page', Number(page) || 1);
        Ember.set(_this, 'pageNumbers.totalPages', Number(Ember.get(m, 'totalPages')));

        return m;
      });
    }
  });
});