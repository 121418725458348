define('wqxr-web-client/routes/settings', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    titleToken: 'Settings',

    model: function model() {
      return this.store.findAll('stream', { reload: true }).then(function (streams) {
        return streams.filterBy('liveWQXR');
      });
    },


    actions: {
      didTransition: function didTransition() {
        window.scrollTo(0, 0);
      }
    }
  });
});