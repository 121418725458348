define('wqxr-web-client/components/setting-queuefinish/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['setting-queuefinish'],
    tagName: 'div',
    currentActionCaption: "start playing WNYC FM",
    init: function init() {
      this._super.apply(this, arguments);
      this.set('finishLinks', [{
        title: "Start playing WNYC FM",
        href: "#",
        action: function action() {}
      }, {
        title: "Start playing WNYC AM",
        href: "#",
        action: function action() {}
      }, {
        title: "Just stop playing",
        href: "#",
        action: function action() {}
      }]);
    }
  });
});