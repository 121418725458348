define('wqxr-web-client/components/jukebox-onboarding-message/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var CANCELLED_COOKIE = 'onboardingCancelled';

  exports.default = Ember.Component.extend({
    cookies: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    showOnboardingMessage: false,

    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('fastboot.isFastBoot') || this.get('cookies').exists(CANCELLED_COOKIE)) {
        return false;
      }
      this.set('showOnboardingMessage', true);
    },


    actions: {
      hideOnboardMessage: function hideOnboardMessage() {
        this.get('cookies').write(CANCELLED_COOKIE, true);
        this.set('showOnboardingMessage', false);
      }
    }
  });
});