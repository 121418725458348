define('wqxr-web-client/utils/analytics-code-parser', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (code) {
    if (!code) {
      /* eslint-disable */
      console.warn('no analytics string supplied');
      /* eslint-enable */
      return {};
    }
    // see puppy/cms/models/content.py#analytics_code_string for fields
    var fields = ['slug', 'audio', 'audioduration', 'video', 'modelchar', 'domainint', 'hasshow', 'haschannel', 'isblog', 'seriestitles', 'channeltitle', 'showtitle', 'tags', 'audiopath'];
    var REGEX = /(?:.*:)?(.*)\W+\$A(\d)\$AD(\d+)\$V(\d)\$M(\w)\$D(\d+)\$HS(\d)\$HC(\d)\$B(\d)\$SS([^$]+)\$C([^$]*)\$S([^$]*)\$T([^$]+)\$AP([^$]+)\$$/;
    var match = code.match(REGEX);
    if (!match) {
      /* eslint-disable */
      console.warn('failure processing analytics code');
      /* eslint-enable */
      return {};
    } else {
      match = match.slice(1);
    }
    var analyticsObject = {};

    var boolFields = ['audio', 'video', 'haschannel', 'hasshow', 'isblog'];
    var numFields = ['audioduration', 'domainint'];
    var arrayFields = { tags: '!', seriestitles: '+' };

    for (var i = 0; i < fields.length; i++) {
      var field = fields[i];
      if (boolFields.includes(field)) {
        analyticsObject[field] = match[i] === "1";
      } else if (numFields.includes(field)) {
        analyticsObject[field] = Number(match[i]);
      } else if (Object.keys(arrayFields).includes(field)) {
        analyticsObject[field] = match[i].split(arrayFields[field]).reject(function (i) {
          return !i;
        });
      } else {
        analyticsObject[field] = match[i];
      }
    }

    return analyticsObject;
  };
});