define('wqxr-web-client/adapters/order', ['exports', 'ember-data', 'wqxr-web-client/config/environment', 'ember-simple-auth/mixins/data-adapter-mixin', 'ember-fetch/mixins/adapter-fetch'], function (exports, _emberData, _environment, _dataAdapterMixin, _adapterFetch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPIAdapter.extend(_adapterFetch.default, _dataAdapterMixin.default, {
    // we are replacing authorize() here, since ember-fetch (needed for FastBoot)
    // overrides ember-simple-auth's ajaxOptions method, which calls authorize().
    // So instead, we do what we used to do in authorize() right here.
    ajaxOptions: function ajaxOptions() {
      var options = this._super.apply(this, arguments);
      var headers = this.get('session').authorize({});
      options.headers = {};
      for (var h in headers) {
        options.headers[h] = headers[h];
      }
      return options;
    },


    host: _environment.default.membershipAPI,
    namespace: 'v1',
    authorize: function authorize(xhr) {
      var headers = this.get('session').authorize({});
      for (var h in headers) {
        xhr.setRequestHeader(h, headers[h]);
      }
    },
    buildURL: function buildURL() {
      var url = this._super.apply(this, arguments);
      return url + '/';
    }
  });
});