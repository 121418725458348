define("wqxr-web-client/helpers/camelize-object", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.camelizeObj = camelizeObj;

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  function camelizeObj(source) {
    var dest = null;

    if (Array.isArray(source)) {
      dest = source.map(function (obj) {
        return camelizeObj(obj);
      });
    } else if (source !== null && (typeof source === "undefined" ? "undefined" : _typeof(source)) === "object") {
      dest = {};
      for (var prop in source) {
        var newKey = prop.camelize();
        dest[newKey] = camelizeObj(source[prop]);
      }
    } else {
      dest = source;
    }

    return dest;
  }

  exports.default = Ember.Helper.helper(camelizeObj);
});