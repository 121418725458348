define('wqxr-web-client/adapters/channel', ['exports', 'nypr-publisher-lib/adapters/channel', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _channel, _dataAdapterMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _channel.default.extend(_dataAdapterMixin.default, {
    authorize: function authorize(xhr) {
      var headers = this.get('session').authorize({});
      for (var h in headers) {
        xhr.setRequestHeader(h, headers[h]);
      }
    }
  });
});