define('wqxr-web-client/components/toggle-box/component', ['exports', 'wqxr-web-client/utils/toggle-box-positioner'], function (exports, _toggleBoxPositioner) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['toggle-box'],
    classNameBindings: ['open:is-active', 'theme', 'hPos', 'vPos'],
    open: false,
    theme: 'dark',
    icon: 'caret-down',
    closeDelay: 5000,
    contentClass: '',
    timer: 0,

    contentClasses: Ember.computed('theme', function () {
      return 'toggle-box__dropdown ' + this.theme + ' ' + this.contentClass;
    }),

    calculatePosition: function calculatePosition(trigger, content, _destination, ref) {
      Ember.run.scheduleOnce('afterRender', function () {
        // position the caret
        var _trigger$getBoundingC = trigger.getBoundingClientRect(),
            triggerLeft = _trigger$getBoundingC.left,
            triggerWidth = _trigger$getBoundingC.width;

        var _content$getBoundingC = content.getBoundingClientRect(),
            contentLeft = _content$getBoundingC.left;

        var caretLeft = triggerLeft - contentLeft + triggerWidth / 2 - 4;
        _destination.style.setProperty("--caret-left-pos", caretLeft + "px");

        // #calculatePosition is called without this component's context
        // so we have to reach into this component through the ref argument
        ref.dropdown.parentView.hookUpContentListeners(content, ref);
      });

      return _toggleBoxPositioner.default.apply(undefined, arguments);
    },
    hookUpContentListeners: function hookUpContentListeners(contentElement, ref) {
      var _this = ref.dropdown.parentView;

      var autoclose = function autoclose() {
        _this.autoclose(ref.dropdown);
      };

      contentElement.addEventListener('mouseenter', autoclose, true);
      contentElement.addEventListener('mousemove', autoclose, true);

      autoclose(); // trigger the first autoclose, which will be cancelled/deferred with element interaction
    },


    autoclose: function autoclose(dropdown) {
      if (this.timer) {
        Ember.run.cancel(this.timer);
        this.timer = 0;
      }
      this.timer = Ember.run.later(this, function () {
        this.timer = 0;

        // These differ based on how they were called, unfortunately
        if (dropdown && dropdown.actions && dropdown.actions.close) {
          dropdown.actions.close();
        } else if (dropdown && dropdown.close) {
          dropdown.close();
        }
      }, this.closeDelay);
    }
  });
});