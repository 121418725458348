define('wqxr-web-client/helpers/producing-orgs', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.producingOrgs = producingOrgs;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function producingOrgs(_ref) {
    var _ref3 = _slicedToArray(_ref, 1),
        orgsList = _ref3[0];

    var _ref2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
        _ref2$unlinked = _ref2.unlinked,
        unlinked = _ref2$unlinked === undefined ? false : _ref2$unlinked;

    var producingString = '';

    orgsList.forEach(function (org, idx) {
      var line = '';

      if (unlinked) {
        line = Ember.get(org, 'name');
      } else {
        line = '<a href="' + Ember.get(org, 'url') + '" target="_blank" class="link link--dark">' + Ember.get(org, 'name') + '</a>';
      }

      if (idx === Ember.get(orgsList, 'length') - 2) {
        line += ' and ';
      } else if (idx < Ember.get(orgsList, 'length') - 1) {
        line += ', ';
      }

      producingString += line;
    });

    return Ember.String.htmlSafe('' + producingString);
  }

  exports.default = Ember.Helper.helper(producingOrgs);
});