define('wqxr-web-client/instance-initializers/google-ads', ['exports', 'nypr-ads/instance-initializers/google-ads'], function (exports, _googleAds) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _googleAds.default;
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function () {
      return _googleAds.initialize;
    }
  });
});