define('wqxr-web-client/mixins/deauthenticated-route-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    session: Ember.inject.service(),

    beforeModel: function beforeModel() {
      if (Ember.get(this, 'session.isAuthenticated')) {
        // we check for the noRefresh flag on
        // sessionInvalidated() on the application route
        Ember.set(this, 'session.noRefresh', true);
        Ember.get(this, 'session').invalidate();
      }
      this._super.apply(this, arguments);
    }
  });
});