define('wqxr-web-client/components/account-wrapper/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    dj: Ember.inject.service(),
    showPlayer: Ember.computed.reads('dj.showPlayer'),
    classNames: ['account-screen'],
    classNameBindings: ['showPlayer:account-screen--player-open']
  });
});